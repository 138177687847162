import { render, staticRenderFns } from "./addNoteSidebar.vue?vue&type=template&id=2e3a5a62&scoped=true&"
import script from "./addNoteSidebar.vue?vue&type=script&lang=js&"
export * from "./addNoteSidebar.vue?vue&type=script&lang=js&"
import style0 from "./addNoteSidebar.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./addNoteSidebar.vue?vue&type=style&index=1&id=2e3a5a62&lang=scss&scoped=true&"
import style2 from "./addNoteSidebar.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3a5a62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ruwanbandara/Documents/10QBIT/hyre_mp_app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e3a5a62')) {
      api.createRecord('2e3a5a62', component.options)
    } else {
      api.reload('2e3a5a62', component.options)
    }
    module.hot.accept("./addNoteSidebar.vue?vue&type=template&id=2e3a5a62&scoped=true&", function () {
      api.rerender('2e3a5a62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff-bank/staff-profile/sidebars/addNoteSidebar.vue"
export default component.exports