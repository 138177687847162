<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" md="6" v-if="openForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="UserIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Personal Details
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="userEditValidateForm">
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
              >
                <b-col class=" " cols="12" xl="12">
                  <b-row class="mt-2">
                    <b-col cols="12" md="12">
                      <label> First Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                      >
                        <b-form-input id="h-first-name"
                                      v-model="form.first_name"
                                      class="mb-1"
                                      placeholder="First Name"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12">
                      <label> Second Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                      >
                        <b-form-input id="h-last-name"
                                      v-model="form.last_name"
                                      class="mb-1"
                                      placeholder="Last Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12">
                      <label>DOB</label>

                      <validation-provider
                          #default="{ errors }"
                          name="Date"
                          rules=""
                      >
                        <b-form-group>
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="CalendarIcon"/>
                            </b-input-group-prepend>
                          <flat-pickr
                              v-model="form.dob"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"
                              class="form-control"
                              placeholder="Select a date"
                          />
                          </b-input-group>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col cols="6" md="12">
                      <b-form-group
                          label="NI Number"
                          label-for="h-Ni_number"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="NI Number"
                            rules="numeric"
                        >
                          <b-input-group class="input-group-merge">

                            <b-form-input
                                id="icons-Ni_number"
                                v-model="form.ni_number"
                                placeholder="Enter NI Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6" md="12">
                      <b-form-group
                          label="Gender"
                          label-for="h-Gender"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Gender"
                            rules=""
                        >
                          <b-input-group class="input-group-merge">

                            <v-select
                                class="w-100"
                                v-model="form.gender"
                                :reduce="title => title.value"
                                :options="[{title:'Male', value:'Male'},
                                         {title:'Female', value:'Female'},
                                         {title:'Non-binary', value:'Non-binary'},
                                         ]"
                                label="title"
                                placeholder="Select Gender"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6" md="12">
                      <b-form-group
                          label="Preferred Pronouns"
                          label-for="h-Preferred_pronouns"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Preferred Pronouns"
                            rules=""
                        >
                          <b-input-group class="input-group-merge">

                            <v-select
                                class="w-100"
                                v-model="form.preferred_pronouns"
                                :reduce="title => title.value"
                                :options="[{title:'They/Them', value:'They/Them'},
                                         {title:'He/Him', value:'He/Him'},
                                         {title:'She/Her', value:'She/Her'},
                                         {title:'Prefer not to say', value:'Prefer not to say'}
                                         ]"
                                label="title"
                                placeholder="Select Preferred Pronouns"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                      <b-form-group
                          label="Alias"
                          label-for="h-Alias"
                      >
<!--                        <validation-provider-->
<!--                            #default="{ errors }"-->
<!--                            name="NI Number"-->
<!--                            :rules="'required|numeric'"-->
<!--                        >-->
                          <b-input-group class="input-group-merge">
                            <b-form-input
                                id="icons-Alias"
                                v-model="form.alias"
                                placeholder="Enter Alias"
                            />
                          </b-input-group>
<!--                          <small class="text-danger">{{ errors[0] }}</small>-->
<!--                        </validation-provider>-->
                      </b-form-group>
                    </b-col>

<!--                    <b-col cols="12" md="6">-->
<!--                      <b-form-group-->
<!--                          label="Email Address"-->
<!--                          label-for="h-email"-->
<!--                      >-->
<!--                        <validation-provider-->
<!--                            #default="{ errors }"-->
<!--                            name="Email"-->
<!--                            rules="required"-->
<!--                        >-->
<!--                          <b-input-group class="input-group-merge">-->
<!--                            <b-input-group-prepend is-text>-->
<!--                              <feather-icon icon="MailIcon"/>-->
<!--                            </b-input-group-prepend>-->
<!--                            <b-form-input-->
<!--                                id="icons-mail"-->
<!--                                v-model="form.email"-->
<!--                                placeholder="example@.com"-->
<!--                            />-->
<!--                          </b-input-group>-->
<!--                          <small class="text-danger">{{ errors[0] }}</small>-->
<!--                        </validation-provider>-->
<!--                      </b-form-group>-->
<!--                    </b-col>-->


                    <b-col v-if="form.locum_job_role" cols="12" md="12">
                      <b-form-group
                          label="Locum Job Role"
                          label-for="locum_job_role"
                      >
                        <b-form-input
                            v-model="form.locum_job_role"
                            readonly
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-row>
                        <div class="mt-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                          Bank Details
                        </div>
                      </b-row>
                    </b-col>

                    <b-col cols="12" md="12">
                      <div class="mt-1">
                        <b-form-group
                            label="Bank Name"
                            label-for="v-bank-name"
                        >
                          <validation-provider
                              #default="{ errors }"
                              name="Bank Name"
                              :rules="bank_details.sort_code || bank_details.account_number?'required':''"
                          >
                            <b-form-input
                                id="v-bank-name"
                                v-model="bank_details.name"
                                placeholder="e.g.Bank of England"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </b-col>

                    <b-col md="12">
                      <div class="">
                        <b-form-group
                            label="Account Number"
                            label-for="account-number"
                        >

                          <validation-provider
                              #default="{ errors }"
                              name="Account Number"
                              :rules="bank_details.name || bank_details.sort_code?'required|numeric|min_bank_account|max_bank_account':'numeric|min_bank_account|max_bank_account'"
                          >
                            <b-form-input
                                id="account-number"
                                v-model="bank_details.account_number"
                                placeholder="xxxxxxxx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                          label="Sort Code"
                          label-for="sort-code"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Sort Code"
                            :rules="bank_details.name || bank_details.account_number?'required|numeric|max_sort_code|min_sort_code':'numeric|max_sort_code|min_sort_code'"
                        >
                          <b-form-input
                              id="sort-code"
                              v-model="bank_details.sort_code"
                              placeholder="xx-xx-xx"

                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col md="12" class="mt-2">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mr-1"
                          type="submit"
                          variant="primary"
                          @click="updateUserInfo()"
                      >
                        Save
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="reset"
                          class="text-primary ml-1 shadow"
                          variant="outline-white"
                          @click="openForm = false"
                      >
                                <span>
                                  Cancel
                                </span>
                      </b-button>
                    </b-col>
                  </b-row>

                </b-col>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="4" md="6" v-if="openForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="UserIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Personal Details
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openEditForm(isEditProfileSidebarActive)"

              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">First Name</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.first_name }}
                </h5>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Second Name</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ user.last_name }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">DOB</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.dob?ChangeFormateDate(user.dob):'-'}}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">NI number</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ user.ni_number ? user.ni_number : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Gender</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.gender ? user.gender : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">Preferred Pronouns</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ user.preferred_pronouns ? user.preferred_pronouns : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Alias </span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.alias ? user.alias : '-' }}
                </h5>
              </b-col>
            </b-row>

            <div class="mt-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
              Bank Details</div>

            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">Bank Name</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.bank_details ? user.bank_details.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Account Number</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ user.bank_details ? user.bank_details.account_number : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Sort Code</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.bank_details ? user.bank_details.sort_code : '-' }}
                </h5>
              </b-col>
            </b-row>

          </b-card-body>

        </b-card>
      </b-col>

      <b-col lg="4" md="6" v-if="openEditContactForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
                <!--                <feather-icon-->
                <!--                    class="text-dark font-weight-700"-->
                <!--                    icon="FileIcon"-->
                <!--                    size="18"-->
                <!--                />-->

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.66634 2.66602H13.333C14.0663 2.66602 14.6663 3.26602 14.6663 3.99935V11.9993C14.6663 12.7327 14.0663 13.3327 13.333 13.3327H2.66634C1.93301 13.3327 1.33301 12.7327 1.33301 11.9993V3.99935C1.33301 3.26602 1.93301 2.66602 2.66634 2.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Contact Details
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>

          <b-card-body>
            <validation-observer ref="EditPersonalInfoForm">
              <b-form @submit.prevent>
                <b-row class="mt-2">
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Mobile"
                        label-for="h-telephone"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Telephone"
                          rules="tel|min_tel|max_tel"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="PhoneIcon"/>
                          </b-input-group-prepend>
                          <b-form-input
                              id="icons-phone"
                              v-model="form.phone_number"
                              placeholder="(+44)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" class="mt-2">
                    <b-row>
                      <div class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                        Home Address
                      </div>

                      <div class="optional-class">
                        <!--                        Optional-->
                      </div>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Address 1"
                          label-for="home-address"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules=""
                        >
                          <b-form-input
                              id="home-address"
                              v-model="home_address.address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="">
                      <b-form-group
                          label="Address 2"
                          label-for="home-address"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules=""
                        >
                          <b-form-input
                              id="home-address1"
                              v-model="home_address.address_2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="12">
                    <div class="">
                      <b-form-group
                          label="City / Town"
                          label-for="city"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                            :rules="home_address.address?'required':''"
                        >
                          <b-form-input
                              id="city"
                              v-model="home_address.city"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="12">
                    <div class="">
                      <b-form-group
                          label="Post Code"
                          label-for="postal-code"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Post Code"
                            :rules="home_address.address?'required':''"
                        >
                          <b-form-input
                              id="postal-code"
                              v-model="home_address.postal_code"
                              placeholder="e.g.PR8 8UW"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" class="mt-2">
                    <b-row>
                      <div class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                        Emergency Contact
                      </div>

<!--                      <div class="optional-class">-->
<!--                        &lt;!&ndash;                        Optional&ndash;&gt;-->
<!--                      </div>-->
                    </b-row>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Emergency contact name"
                          label-for="v-bank-name"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Bank Name"
                            rules=""
                        >
                          <b-form-input
                              id="v-bank-name1"
                              v-model="emergency_contact.contact_name"
                              placeholder="Enter Name "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="">
                      <b-form-group
                          label="Emergency contact number"
                          label-for="account-number"
                      >

                        <validation-provider
                            #default="{ errors }"
                            name="Telephone"
                            rules="tel|min_tel|max_tel"
                        >
                          <b-form-input
                              id="account-number1"
                              v-model="emergency_contact.contact_number"
                              placeholder="Enter contact number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </div>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col md="12" class="mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mr-1"
                        type="submit"
                        variant="primary"
                        @click="profileUpdate()"
                    >
                      Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        class="text-primary ml-1 shadow"
                        type="button"
                        variant="outline-white"
                        @click="openEditContactForm=false"
                    >
                      <span class="text-primary">
                     Cancel
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>



      <b-col lg="4" md="6" v-if="openEditContactForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
<!--                <feather-icon-->
<!--                    class="text-dark font-weight-700"-->
<!--                    icon="FileIcon"-->
<!--                    size="18"-->
<!--                />-->

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.66634 2.66602H13.333C14.0663 2.66602 14.6663 3.26602 14.6663 3.99935V11.9993C14.6663 12.7327 14.0663 13.3327 13.333 13.3327H2.66634C1.93301 13.3327 1.33301 12.7327 1.33301 11.9993V3.99935C1.33301 3.26602 1.93301 2.66602 2.66634 2.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Contact Details
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openContactDetailsEditForm(isEditContactInfoFormActive)"
              >
              <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  class="text-primary cursor-pointer"
                  icon="EditIcon"
                  size="20"
              />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Contact Number'" icon="PhoneIcon"/>
                {{ user.phone_number ? user.phone_number : '-' }}
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Contact Number'" icon="AtSignIcon"/>
                {{ user.email ? user.email : '-' }}
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Address'" icon="MapPinIcon"/>
                <span v-if="user.home_addresses">
                 <span style="margin-left: 1px">{{user.home_addresses.address?user.home_addresses.address+', ':''}} </span> <br>
                  <span style="margin-left: 28px">{{user.home_addresses.address_2?user.home_addresses.address_2+', ':''}} </span> <br>
                <span style="margin-left: 28px">{{user.home_addresses.city?user.home_addresses.city+', ':''}} </span> <br>
                 <span style="margin-left: 28px">{{user.home_addresses.postal_code?user.home_addresses.postal_code:''}} </span>
                </span>
                <span v-else>
                  -
                </span>
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1">
                <feather-icon v-b-tooltip.hover.top="'Emergency Contact Number'" icon="AlertCircleIcon"/>
               <span style="margin-left: 10px" class="details"> {{user.emergency_contacts? user.emergency_contacts.contact_name+' - ' + user.emergency_contacts.contact_number:'-'}} </span>
              </span>
            </div>

          </b-card-body>

        </b-card>
      </b-col>

      <b-col lg="4" md="6" v-if="openProfessionalInfoForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
                <!--                <feather-icon-->
                <!--                    class="text-dark font-weight-700"-->
                <!--                    icon="FileIcon"-->
                <!--                    size="18"-->
                <!--                />-->

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.333 4.66602H2.66634C1.92996 4.66602 1.33301 5.26297 1.33301 5.99935V12.666C1.33301 13.4024 1.92996 13.9993 2.66634 13.9993H13.333C14.0694 13.9993 14.6663 13.4024 14.6663 12.666V5.99935C14.6663 5.26297 14.0694 4.66602 13.333 4.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Professional Details
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>

          <b-card-body>
            <validation-observer
                ref="professionalInfoForm"
                #default="{ handleSubmit }"
            >
              <b-form
                  class="auth-login-form mt-2"
              >
                <div class="mr-2 ml-2">
                  <b-row class="">
                    <!-- Qualification -->
                    <b-col cols="12" md="12">
                      <b-form-group
                          label="Job Role"
                          label-for="h-job-role"
                      >
                      </b-form-group>
                    </b-col>
                    <b-col class="mb-1" cols="12" md="12">
                      <b-form-group>

                        <validation-provider
                            #default="{ errors }"
                            name="Job Role"
                            rules="required"
                        >
                          <v-select
                              v-model="job_role"
                              :options="dropdowns.job_roles"
                              placeholder="Select Job Role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                      <b-form-group
                          label="Qualification"
                          label-for="h-qualification-name"
                      >
                      </b-form-group>
                    </b-col>

                    <b-col class="mb-1" cols="12" md="12">
                      <!--                <validation-provider-->
                      <!--                    #default="{ errors }"-->
                      <!--                    name="qualification"-->
                      <!--                    rules="required"-->
                      <!--                >-->
                      <v-select
                          v-model="professionalInfo.qualification_id"
                          :options="dropdowns.qualifications"
                          placeholder="Select Qualification"
                      />
                      <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
                      <!--                </validation-provider>-->
                    </b-col>

                    <!-- Speciality -->
                    <b-col cols="12" md="12">
                      <b-form-group
                          label="Speciality"
                          label-for="h-speciality-name"
                      >
                      </b-form-group>
                    </b-col>
                    <b-col class="mb-1" cols="12" md="12">
                      <b-form-group>
                        <!--                  <validation-provider-->
                        <!--                      #default="{ errors }"-->
                        <!--                      name="speciality"-->
                        <!--                      rules="required"-->
                        <!--                  >-->
                        <v-select
                            v-model="professionalInfo.speciality_id"
                            :options="dropdowns.specialities"
                            placeholder="Select Speciality"
                        />
                        <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
                        <!--                  </validation-provider>-->
                      </b-form-group>
                    </b-col>
                    <!-- IT System -->

                    <b-col cols="12" md="12">
                      <b-form-group
                          label="IT System"
                          label-for="IT system"
                      >
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group class="mb-1">
                        <!--                  <validation-provider-->
                        <!--                      #default="{ errors }"-->
                        <!--                      name="IT system"-->
                        <!--                      rules="required"-->
                        <!--                      vid="ITSystem"-->
                        <!--                  >-->
                        <v-select
                            v-model="professionalInfoItSystems"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="dropdowns.it_systems"
                            multiple
                            placeholder="Select IT System"
                        />
                        <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
                        <!--                  </validation-provider>-->
                      </b-form-group>
                    </b-col>


                    <b-col cols="12" md="12">

                      <b-form-group
                          label="Smart Card Number"
                          label-for="SmartCardNumber"
                      >
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group>
                        <validation-provider
                            #default="{ errors }"
                            name="Smart card number"
                            rules="max_input|numeric"
                            vid="SmartCardNumber"
                        >
                          <b-form-input
                              id="SmartCardNumber"
                              v-model="professionalInfo.smart_card_number"
                              class="mb-1"
                              placeholder="Enter smart card number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                      <b-form-group
                          label="Skills"
                          label-for="h-skills"
                      >
                      </b-form-group>
                    </b-col>
                    <b-col class="mb-3" cols="12" md="12">
                      <b-form-group>
                        <!--                  <validation-provider-->
                        <!--                      #default="{ errors }"-->
                        <!--                      name="Skills"-->
                        <!--                      rules="required"-->
                        <!--                  >-->
                        <v-select
                            v-model="professionalInfo.skills"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            multiple
                            placeholder="Add skills"
                            push-tags
                            taggable
                        />
                        <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
                        <!--                  </validation-provider>-->
                      </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col md="12">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mr-1"
                          type="submit"
                          variant="primary"
                          @click.prevent="submitForm"
                      >
                        Save
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          class="text-primary ml-1 shadow"
                          type="button"
                          variant="outline-white"
                          @click="openProfessionalInfoForm = false"
                      >
        <span class="text-primary">
          Cancel
        </span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>


      <b-col lg="4" md="6" v-if="openProfessionalInfoForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
<!--                <feather-icon-->
<!--                    class="text-dark font-weight-700"-->
<!--                    icon="FileIcon"-->
<!--                    size="18"-->
<!--                />-->

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.333 4.66602H2.66634C1.92996 4.66602 1.33301 5.26297 1.33301 5.99935V12.666C1.33301 13.4024 1.92996 13.9993 2.66634 13.9993H13.333C14.0694 13.9993 14.6663 13.4024 14.6663 12.666V5.99935C14.6663 5.26297 14.0694 4.66602 13.333 4.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Professional Details
                </h4>
              </div>
            </div>
            <div class="ml-auto">

              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click = openEditProfessionalInfoForm(isEditProfessionalInfoFormActive)
              >
              <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  class="text-primary cursor-pointer"
                  icon="EditIcon"
                  size="20"
              />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">Job Role</span> <br>
                <span class="details" style="margin-top: 10px">{{user.job_roles || user.job_roles[0]?user.job_roles[0].name:''}}</span>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Qualification</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ user.qalifications && user.qalifications.qalification ? user.qalifications.qalification.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Speciality</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ user.qalifications && user.qalifications.speciality ? user.qalifications.speciality.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">IT System</span> <br>
                <b-row class="pl-0 ">
                  <b-col class="mt-1 font-weight-light" cols="12">
                <b-badge class="mr-1 ml-4" style="margin-top: 5px" variant="light-info" v-for="professionalInfoItSystem in professionalInfoItSystems">
                  <span class="text-dark details">{{professionalInfoItSystem.label}}</span>
                </b-badge>
                <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 ml-5">-
                </h5>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Smart Card Number</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{user.qalifications && user.qalifications.smart_card_number?user.qalifications.smart_card_number:'-'}}
                </h5>
              </b-col>

              <b-col lg="12" class="mt-2">
                <span class="header">Skills</span> <br>
                <div>
                  <b-row v-if="user.qalifications && user.qalifications.skills" class="pl-0 ">
                    <b-col class="mt-1 font-weight-light" cols="12">
                      <b-badge class="mr-1" variant="light-info" v-for="skill in user.qalifications.skills">
                        <span class="text-dark details">{{skill}}</span>
                      </b-badge>
                      <h5 v-if="Object.keys(user.qalifications.skills).length === 0"  class="mb-0 ">-
                      </h5>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <span class="ml-2">-</span>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
<!--                <feather-icon-->
<!--                    class="text-dark font-weight-700"-->
<!--                    icon="FileIcon"-->
<!--                    size="18"-->
<!--                />-->
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 13.334H14" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11 2.33414C11.2652 2.06892 11.6249 1.91992 12 1.91992C12.1857 1.91992 12.3696 1.9565 12.5412 2.02757C12.7128 2.09864 12.8687 2.20281 13 2.33414C13.1313 2.46546 13.2355 2.62136 13.3066 2.79294C13.3776 2.96452 13.4142 3.14842 13.4142 3.33414C13.4142 3.51985 13.3776 3.70375 13.3066 3.87533C13.2355 4.04691 13.1313 4.20281 13 4.33414L4.66667 12.6675L2 13.3341L2.66667 10.6675L11 2.33414Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Notes
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <feather-icon
                  v-b-tooltip.hover.top="'Add Notes'"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="20"
                  @click="toggleSidebar()"
              />
            </div>
          </b-card-header>

          <b-card-body class=" pl-1 px-0 mx-0">
            <b-row v-if="noUserNotes !== 0">
              <b-col cols="12">
                <b-overlay
                    :show="tableLoading"
                    rounded="sm"
                >
                  <b-table
                      ref="table"
                      :current-page="currentPage"
                      :per-page="pagination.perPage"
                      :fields="fields"
                      :filter="filter"
                      :items="getUserNotes"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      hover
                      class="mobile_table_css table_shifts"
                      responsive
                      style="max-height: 400px"
                  >
                    <template #cell(note)="data">
                      <b-row>
                        <b-col cols="1">
                        <svg width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="2" height="38" fill="#00C49A"/>
                        </svg>
                        </b-col>
                        <b-col cols="10">
                        <span class="ml-0" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: rgba(23, 24, 34, 0.6);">
                          Added on {{data.item.date}} <br>
                         <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822; word-break: break-all;"> {{ data.item.note }} </span>
                        </span>
                        </b-col>

                        <b-col cols="1" class="ml-auto">
                          <b-button
                              v-b-tooltip.hover.left="'Delete'"
                              class="btn-icon bg-white mr-0"
                              size="23"
                              variant="outline-white"
                              @click="deleteNote(data.item.id)"
                          >
                            <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>

<!--                    <template #cell(action)="data" style="min-width:20px">-->
<!--                      <div class="d-flex justify-content-end">-->
<!--&lt;!&ndash;                        <b-button&ndash;&gt;-->
<!--&lt;!&ndash;                            v-b-tooltip.hover.left="'Edit'"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="btn-icon bg-white text-primary"&ndash;&gt;-->
<!--&lt;!&ndash;                            size="23"&ndash;&gt;-->
<!--&lt;!&ndash;                            variant="outline-white"&ndash;&gt;-->
<!--&lt;!&ndash;                            @click="toggleEditSidebar(data.item)"&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                          <feather-icon icon="EditIcon" size="18"/>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-button>&ndash;&gt;-->
<!--                        <b-button-->
<!--                            v-b-tooltip.hover.left="'Delete'"-->
<!--                            class="btn-icon bg-white  ml-1"-->
<!--                            size="23"-->
<!--                            variant="outline-white"-->
<!--                            @click="deleteNote(data.item.id)"-->
<!--                        >-->
<!--                          <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>-->
<!--                        </b-button>-->
<!--                      </div>-->
<!--                    </template>-->


                  </b-table>
                </b-overlay>
              </b-col>

            </b-row>
            <div v-else class="d-flex flex-column align-items-center justify-content-center h-10">

              <div class="empty-state py-1">
                <img class="img-fluid" src="@/assets/images/pages/doc-icon.png" alt="" width="100">
                <p class="txt-grey mt-1">No Notes</p>
              </div>

            </div>
          </b-card-body>

        </b-card>
      </b-col>



    </b-row>

    <AddNoteSidebar
        ref="AddNoteSidebar"
        :is-add-note-sidebar-active="isAddNoteSidebarActive"
        @toggleSidebar="toggleSidebar()"
        @dataRefresh="dataRefresh()"
    />
<!--    <EditNoteSidebar-->
<!--        ref="EditNoteSidebar"-->
<!--        :is-edit-note-sidebar-active="isEditNoteSidebarActive"-->
<!--        @dataRefresh="dataRefresh()"-->
<!--        :note-details="noteDetails"-->
<!--    />-->

    <EditProfileSidebar
        @close="toggleSideBars"
        ref="AddLocationSideBar"
        :is-edit-profile-sidebar-active.sync="isEditProfileSidebarActive"
        :User="user.job_roles || user.job_roles[0]?user.job_roles[0]:''"
        @toggleSideBars="toggleSideBars()"
    />

<!--    @updatedDetails="updatedDetails"-->
<!--    @updatedRole="updatedRole"-->
<!--    @refreshProfessionalInfo="updateProfessionalInfo"-->

  </div>

</template>

<script>
import EditProfileSidebar from '@/views/staff-bank/sidebar/EditProfile'
import RequestLeave from '@/views/staff-bank/staff-profile/sidebars/requestLeave'
import ProfileDownloadDropDownMenu
  from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import AddNoteSidebar from '../sidebars/addNoteSidebar.vue'
// import EditNoteSidebar from '../sidebars/editNoteSidebar.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BForm, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab, BTabs,BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import AddLeaveSidebar from '@/views/admin-leave/sidebars/addLeaveSidebar'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import UserNoteAPI from '@/apis/modules/user_notes'
import practice from '@/apis/modules/practice'
import staffBank from '@/apis/modules/staffBank'

export default {
  name: 'personalInfo',
  components: {
    flatPickr,
    EditProfileSidebar,
    AddNoteSidebar,
    // EditNoteSidebar,
    RequestLeave,
    ProfileDownloadDropDownMenu,
    DropDownMenu,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BForm,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    AddLeaveSidebar,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data(){
    return {
      isEditProfessionalInfoFormActive:true,
      openProfessionalInfoForm:false,
      addLoader: false,
      dropdowns: {
        qualifications: [],
        specialities: [],
        it_systems: [],
        job_roles: []
      },
      dir: 'ltr',
      selected: null,
      option: [{title: 'Dietology'}, {title: 'Food Processing '}, {title: 'Nutrition'}, {title: 'Biochemistry'}],
      job_role_id:'',
      job_role:'',
      it_systems:[],
      openEditContactForm:false,
      isEditContactInfoFormActive:true,
      professionalInformation:{},
      professionalInfo: {},
      professionalInformationItSystems: {},
      changeImageButton: true,
      uploadImage: [],
      jobRoleOption: [],
      personalInfoLoading: false,
      selectedJobRole: {},
      show: false,
      removeButton: true,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        role: '',
        locum_job_role: '',
        gender:'',
        alias:'',
        preferred_pronouns:'',
        ni_number:'',
        dob:''
      },
      bank_details: {
        name: '',
        account_number: '',
        sort_code: ''
      },
      home_address: {
        address: '',
        city: '',
        postal_code: ''
      },
      emergency_contact: {
        contact_name:'',
        contact_number:''
      },
      utcConvertedUnAvalibility: [],
      openForm:false,
      professionalInfoItSystems:{},
      isEditProfileSidebarActive:true,
      user:'',
      isAddNoteSidebarActive: false,
      isEditNoteSidebarActive: false,
      leaves: [],
      tableLoading:false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      noteDetails:{},
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'note',
          label:'',
        },

        {
          key:'action',
          label:''
        }
      ],
      items: [],
      noUserNotes:''
    }
  },
  async mounted () {
    await this.getUserDetails()
    await this.getJobRoleList()
    await this.getQualifications()
    if(_.isEmpty(this.professionalInfo,true)) return
    this.professionalInfo.qualification_id = _.find(this.dropdowns.qualifications,{value:this.professionalInfo.qualification_id}) || this.professionalInfo.qualification_id
    this.professionalInfo.it_system_id = _.find(this.dropdowns.it_systems,{value:this.professionalInfo.it_system_id}) || this.professionalInfo.it_system_id
    this.professionalInfo.speciality_id = _.find(this.dropdowns.specialities,{value:this.professionalInfo.speciality_id}) || this.professionalInfo.speciality_id
  },

  methods:{
    openEditForm(val){
      this.openForm = val
    },

    openContactDetailsEditForm(val){
        this.openEditContactForm = val
    },

    openEditProfessionalInfoForm(val){
      this.openProfessionalInfoForm = val
    },

    toggleSideBars() {
      this.isEditProfileSidebarActive = !this.isEditProfileSidebarActive
    },
    // updatedDetails(data) {
    //   this.getUserDetails()
    // },
    // updatedRole(data) {
    //   this.user.job_roles[0].name = data
    // },
    // async updateProfessionalInfo () {
    //   await this.getUserDetails()
    // },
    async getUserDetails(){
      try {
        const Response =  await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data
       // console.log('this.user',this.user)

        this.job_role = this.user && this.user.job_roles && this.user.job_roles[0]?this.user.job_roles[0].name:''
        this.job_role_id = this.user && this.user.job_roles && this.user.job_roles[0]?this.user.job_roles[0].id:''
        this.professionalInformation = this.user.qalifications?this.user.qalifications || {}: ''
        this.professionalInfo = this.user? this.user:''
        this.form.first_name = this.user && this.user.first_name ? this.user.first_name:''
        this.form.last_name = this.user && this.user.last_name ? this.user.last_name:''
        this.form.dob = this.user && this.user.dob ? this.user.dob:''
        this.form.phone_number = this.user && this.user.phone_number ? this.user.phone_number:''
        this.form.email = this.user && this.user.email ? this.user.email:''
        this.form.alias = this.user && this.user.alias ? this.user.alias:''
        this.form.ni_number = this.user && this.user.ni_number ? this.user.ni_number:''
        this.form.preferred_pronouns = this.user && this.user.preferred_pronouns ? this.user.preferred_pronouns:''
        this.form.gender = this.user && this.user.gender ? this.user.gender : ''
        this.bank_details.name = this.user && this.user.bank_details?this.user.bank_details.name:''
        this.bank_details.account_number = this.user && this.user.bank_details?this.user.bank_details.account_number:''
        this.bank_details.sort_code = this.user && this.user.bank_details?this.user.bank_details.sort_code:''
        this.form.locum_job_role = this.user && this.user.locum ? this.user.locum.job_role.name : ''
        this.selectedJobRole = {
          name: this.user && this.user.job_roles && this.user.job_roles[0]?this.user.job_roles[0].name:'',
          id: this.user && this.user.job_roles && this.user.job_roles[0]? this.user.job_roles[0].id:''
        }
        this.professionalInfo = this.user.qalifications===null?{}:this.user.qalifications
        this.professionalInfoItSystems = this.user.multiple_it_systems.map((x) => ({
          value: x.it_system[0].id,
          label: x.it_system[0].name,
        }))
        this.emergency_contact.contact_name = this.user && this.user.emergency_contacts ? this.user.emergency_contacts.contact_name:''
        this.emergency_contact.contact_number = this.user && this.user.emergency_contacts?this.user.emergency_contacts.contact_number:''
        this.home_address.address =  this.user && this.user.home_addresses.address
        this.home_address.address_2 = this.user && this.user.home_addresses ? this.user.home_addresses.address_2 : ''
        this.home_address.city = this.user && this.user.home_addresses ? this.user.home_addresses.city : ''
        this.home_address.postal_code = this.user && this.user.home_addresses  ? this.user.home_addresses.postal_code : ''

        if(_.isEmpty(this.professionalInfo,true)) return
        this.professionalInfo.qualification_id = _.find(this.dropdowns.qualifications,{value:this.professionalInfo.qualification_id}) || this.professionalInfo.qualification_id
        this.professionalInfo.it_system_id = _.find(this.dropdowns.it_systems,{value:this.professionalInfo.it_system_id}) || this.professionalInfo.it_system_id
        this.professionalInfo.speciality_id = _.find(this.dropdowns.specialities,{value:this.professionalInfo.speciality_id}) || this.professionalInfo.speciality_id
        // this.professionalInfoItSystems = this.user.multiple_it_systems.length === 0 || this.UserProfessionalInformation.multiple_it_systems[0].it_system.length===0?'':this.UserProfessionalInformation.multiple_it_systems.map((x) => ({
        //   value: x.it_system[0].id,
        //   label: x.it_system[0].name,
        // }))
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    async updateUserInfo () {
      if (await this.$refs.userEditValidateForm.validate()) {
        try {
          const payload = {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email,
            phone_number: this.form.phone_number,
            gender: this.form.gender,
            alias: this.form.alias,
            preferred_pronouns: this.form.preferred_pronouns,
            ni_number:this.form.ni_number,
            dob:this.form.dob?moment(this.form.dob).utc().format('YYYY-MM-DD'):''
          }
          if (this.bank_details.name) {
            payload.bank_details = this.bank_details
          }
          // if (this.home_address.address) {
          //   payload.home_address = this.home_address
          // }
          // await profile.profileUpdate(payload)
          const response = await staffBank.updateSalariedStaffPersonalInfo(this.$route.params.id, payload)
          this.showSuccessMessage('Profile updated')
          this.$emit('updateDetails')
          this.openForm = false
          await this.getUserDetails()
        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
    },

    async profileUpdate() {
      if (await this.$refs.EditPersonalInfoForm.validate()) {
        try {
          const payload = {
            phone_number: this.form.phone_number,
          }

          if (this.home_address.address) {
            payload.home_address = this.home_address
          }
          if(this.emergency_contact.contact_name) {
            payload.emergency_contact = this.emergency_contact
          }
          await staffBank.updateContactInfo(this.$route.params.id, payload)
          this.showSuccessMessage('Profile updated')
          await this.getUserDetails()
          this.openEditContactForm = false
          await this.$store.dispatch('autoLogin')
        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
    },

    ChangeFormateDate(oldDate){
      return oldDate.toString().split("-").reverse().join("-");
    },

    async getUserNotes(){
      try {
        this.tableLoading = true
        const Response = await UserNoteAPI.getUserNotes(this.$route.params.id, 5, this.currentPage)
        // console.log('Response',Response)
        this.noUserNotes = Response.data.data.length
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          note: x.note,
          date: this.momentFormat(x.created_at,'DD/MM/YYYY')
        }))

        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteNote(id){
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to delete this note?',
          text: '',
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {

          if (result.value) {
            this.tableLoading = true
            const Response = UserNoteAPI.deleteNote(id)
            this.showSuccessMessage('Note deleted successfully')
            this.$refs.table.refresh()
          }
        })
        this.$refs.table.refresh()
        this.tableLoading = false

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },

    toggleSidebar() {
      this.isAddNoteSidebarActive = !this.isAddNoteSidebarActive
    },
    // toggleEditSidebar(item) {
    //   this.noteDetails = item
    //   this.isEditNoteSidebarActive = !this.isEditNoteSidebarActive
    // },
    dataRefresh(){
      try{
        this.$refs.table.refresh()
      }catch(e){
        this.noUserNotes = 1
      }
      this.isAddNoteSidebarActive = false
      // this.isEditNoteSidebarActive = false
    },

    // setProfessionalInfo() {
    //   this.professionalInfo = this.user.qalifications===null?{}:this.UserProfessionalInformation.qalifications
    //   this.professionalInfoItSystems = this.UserProfessionalInformation.multiple_it_systems.length === 0 || this.UserProfessionalInformation.multiple_it_systems[0].it_system.length===0?'':this.UserProfessionalInformation.multiple_it_systems.map((x) => ({
    //     value: x.it_system[0].id,
    //     label: x.it_system[0].name,
    //   }))
    //
    // },
    async submitForm () {
      this.addLoader = true
      this.$refs.professionalInfoForm.validate().then(async success => {
        if (success) {
        //  console.log('professionalInfoItSystems',this.professionalInfoItSystems)
          const it_systems= []
          if(this.professionalInfoItSystems){
            this.professionalInfoItSystems.forEach(function(key){
              it_systems.push(key.value)
            })}
          let payload = {}
          // payload.job_role_id = this.professionalInfo.job_roles.name? this.professionalInfo.job_roles.name.value : null
          payload.job_role_id = this.job_role.id?this.job_role.id:this.job_role_id
          payload.user_id = this.user.id
          // payload.it_system_id = this.professionalInfo.it_system_id ? it_systems : null
          payload.it_systems = it_systems?it_systems:null
          payload.it_system_id =  null
          payload.qualification_id = this.professionalInfo.qualification_id ? this.professionalInfo.qualification_id.value : null
          payload.speciality_id = this.professionalInfo.speciality_id ? this.professionalInfo.speciality_id.value : null
          payload.skills = this.professionalInfo.skills
          payload.smart_card_number = this.professionalInfo.smart_card_number
          try {
            let updatedQualifications = (await staffBank.updateProfessionalInfo(payload, this.$route.params.id)).data.success
            this.addLoader = false
            this.showSuccessMessage('Professional Info updated successfully')
            await this.getUserDetails()
            this.$emit('updateDetails')
            this.openProfessionalInfoForm = false
            this.addLoader = false

          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      this.addLoader = false

    },
    async getQualifications () {
      this.addLoader = true
      let qualificationTypes = (await staffBank.getQualificationsList()).data.data
      this.dropdowns.qualifications = qualificationTypes.qualification.map((x) => ({
        value: x.id,
        label: x.name
      }))
      this.dropdowns.specialities = qualificationTypes.speciality.map((x) => ({
        value: x.id,
        label: x.name
      }))
      this.dropdowns.it_systems = qualificationTypes.it_system.map((x) => ({
        value: x.id,
        label: x.name
      }))

      this.addLoader = false
    },

    async getJobRoleList() {
      const response = (await staffBank.getJobRoles()).data.data
      this.dropdowns.job_roles = response.map((x) => ({
        id: x.id,
        label: x.name
      }))
    }

  },
}

</script>

<style>
.header {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 24, 34, 0.6);
}

.details{
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #171822;
}

.table_shifts table thead{
 display: none !important;
}

</style>