var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "6", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "FileIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "Mulish",
                                  "font-style": "normal",
                                  "font-weight": "bold",
                                  "font-size": "20px",
                                  "line-height": "25px",
                                  color: "#171822"
                                }
                              },
                              [_vm._v(" Documents ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "btn-icon bg-white shadow-sm font-weight-bold",
                            attrs: { variant: "white" },
                            on: {
                              click: function($event) {
                                return _vm.openFileUploadModel()
                              }
                            }
                          },
                          [
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: "Add Documents",
                                  expression: "'Add Documents'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "text-primary",
                              attrs: { icon: "PlusIcon", size: "20" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        { staticClass: "p-1" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-table",
                                {
                                  ref: "table",
                                  staticClass: "mobile_table_css",
                                  attrs: {
                                    "current-page": _vm.currentPage,
                                    fields: _vm.fields,
                                    items: _vm.getList,
                                    "per-page": _vm.pagination.perPage,
                                    "sort-by": _vm.sortBy,
                                    "sort-desc": _vm.sortDesc,
                                    "sort-direction": _vm.sortDirection,
                                    hover: "",
                                    responsive: ""
                                  },
                                  on: {
                                    "update:sortBy": function($event) {
                                      _vm.sortBy = $event
                                    },
                                    "update:sort-by": function($event) {
                                      _vm.sortBy = $event
                                    },
                                    "update:sortDesc": function($event) {
                                      _vm.sortDesc = $event
                                    },
                                    "update:sort-desc": function($event) {
                                      _vm.sortDesc = $event
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "head(created_at)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.viewport",
                                                  value: "Sort",
                                                  expression: "'Sort'",
                                                  modifiers: {
                                                    hover: true,
                                                    viewport: true
                                                  }
                                                }
                                              ]
                                            },
                                            [_vm._v(_vm._s(data.label))]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "table-row",
                                      fn: function(data) {
                                        return undefined
                                      }
                                    },
                                    {
                                      key: "cell(name)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-media",
                                                { attrs: { "no-body": "" } },
                                                [
                                                  _c("b-img", {
                                                    staticClass: "mr-1 ",
                                                    attrs: {
                                                      src: require("@/assets/images/filetypes/pdf.png"),
                                                      alt: "browser img",
                                                      height: "30"
                                                    }
                                                  }),
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "align-self-center my-auto font-weight-bolder"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "cell(action)",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  value: "Download",
                                                  expression: "'Download'",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "btn-icon bg-white text-primary m-1",
                                              attrs: {
                                                size: "23",
                                                variant: "outline-white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadFile(
                                                    data.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "DownloadIcon",
                                                  size: "18",
                                                  variant: "outline-primary"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  value: "Delete",
                                                  expression: "'Delete'",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                },
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass:
                                                "bg-white text-danger btn-icon ml-1",
                                              attrs: {
                                                size: "23",
                                                variant: "outline-danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteFile(
                                                    data.item.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "TrashIcon",
                                                  size: "18",
                                                  variant: "outline-danger"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [_vm._v(" > ")]
                              )
                            ],
                            1
                          ),
                          _vm.noDataTable === 0
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [_c("span", [_vm._v("No data for preview")])]
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-sm-start",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "Showing " +
                                    _vm._s(_vm.pagination.from) +
                                    " to " +
                                    _vm._s(_vm.pagination.to) +
                                    " of " +
                                    _vm._s(_vm.pagination.totalRows) +
                                    " entries"
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-sm-end",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "mb-0 mt-1 mt-sm-0 ",
                                attrs: {
                                  "per-page": _vm.pagination.perPage,
                                  "total-rows": _vm.pagination.totalRows,
                                  "first-number": "",
                                  "last-number": "",
                                  "next-class": "next-item",
                                  "prev-class": "prev-item"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prev-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronLeftIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "next-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronRightIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.currentPage,
                                  callback: function($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-center",
                    centered: "",
                    "hide-footer": "",
                    "no-close-on-backdrop": "",
                    size: "lg",
                    title: "Upload Document"
                  },
                  model: {
                    value: _vm.showFileUploadModal,
                    callback: function($$v) {
                      _vm.showFileUploadModal = $$v
                    },
                    expression: "showFileUploadModal"
                  }
                },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.modalLoading, rounded: "sm" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mx-1" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-auto",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticStyle: {
                                        "enable-background": "new 0 0 512 512"
                                      },
                                      attrs: {
                                        id: "Layer_1",
                                        version: "1.1",
                                        viewBox: "0 0 512 512",
                                        x: "0px",
                                        "xml:space": "preserve",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "xmlns:xlink":
                                          "http://www.w3.org/1999/xlink",
                                        y: "0px"
                                      }
                                    },
                                    [
                                      _c("rect", {
                                        staticStyle: { fill: "#32BEA6" },
                                        attrs: {
                                          height: "512",
                                          width: "512",
                                          y: "0"
                                        }
                                      }),
                                      _c("g", [
                                        _c("polygon", {
                                          staticStyle: { fill: "#FFFFFF" },
                                          attrs: {
                                            points:
                                              "182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808\n\t\t329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 \t"
                                          }
                                        }),
                                        _c("polygon", {
                                          staticStyle: { fill: "#FFFFFF" },
                                          attrs: {
                                            points:
                                              "395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864\n\t\t379.528,405.864 379.528,301.088 395.528,301.088 \t"
                                          }
                                        })
                                      ]),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g"),
                                      _c("g")
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mt-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show: _vm.buttonLoading,
                                    rounded: "sm"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      options: _vm.fileTypeOption,
                                      reduce: function(fileTypeOption) {
                                        return fileTypeOption.id
                                      },
                                      label: "name",
                                      placeholder: "Select Document Type",
                                      value: "id"
                                    },
                                    model: {
                                      value: _vm.selected_file_type_id,
                                      callback: function($$v) {
                                        _vm.selected_file_type_id = $$v
                                      },
                                      expression: "selected_file_type_id"
                                    }
                                  }),
                                  _c("b-form-file", {
                                    ref: "image_ref",
                                    attrs: {
                                      "drop-placeholder": "Drop file here...",
                                      placeholder:
                                        "Choose a file or drop it here..."
                                    },
                                    model: {
                                      value: _vm.uploadedFile,
                                      callback: function($$v) {
                                        _vm.uploadedFile = $$v
                                      },
                                      expression: "uploadedFile"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mt-5", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        disabled: _vm.isActive,
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.uploadFile()
                                        }
                                      }
                                    },
                                    [_vm._v(" Upload ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "warning" },
                                      on: {
                                        click: function($event) {
                                          ;(_vm.isActive = true),
                                            (_vm.showFileUploadModal = false)
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.openEditMandatoryTrainingForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "BookOpenIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Mandatory Training ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openForm(
                                        _vm.isActiveEditMandatoryTrainingForm
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Infection Control Anaphylaxis")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on May 18, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expires on May 18, 2022")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Basic Life Support / CPR 41")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on Dec 7, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expires on Dec 7, 2022")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Management of Emergency Situations ")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on May 18, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Non-renewal")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Health and Safety Fire Safety")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on May 18, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expires on Dec 7, 2023")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Evacuation")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on Dec 24, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expires on Jun 24, 2022")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Information Governance / GDPR ")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-primary m-1",
                                attrs: { icon: "CheckCircleIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on Dec 7, 2021 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expires on Dec 7, 2022")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Equality and Diversity ")]
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("feather-icon", {
                                staticClass: "text-danger m-1",
                                attrs: { icon: "AlertOctagonIcon", size: "20" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: {
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "normal",
                                    "font-size": "14px",
                                    "line-height": "21px",
                                    color: "#171822"
                                  }
                                },
                                [
                                  _vm._v(" Completed on Dec 9, 2020 "),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-danger",
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "#949494"
                                      }
                                    },
                                    [_vm._v(" Expired on Dec 9, 2021")]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-auto mr-2 mt-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v(" View Credential ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#EBF5FB",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "m-1",
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    "font-family": "Mulish",
                                    "font-style": "normal",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    "line-height": "15px",
                                    "text-transform": "uppercase",
                                    color: "#171822"
                                  }
                                },
                                [_vm._v(" Bullying and Harassment ")]
                              )
                            ]
                          ),
                          _c("b-row", [
                            _c(
                              "svg",
                              {
                                staticClass: "mt-1 ml-1",
                                attrs: {
                                  width: "18",
                                  height: "18",
                                  viewBox: "0 0 18 18",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M9 1.5V4.5",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M9 13.5V16.5",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M3.69727 3.69531L5.81977 5.81781",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M12.1797 12.1797L14.3022 14.3022",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M1.5 9H4.5",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M13.5 9H16.5",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M3.69727 14.3022L5.81977 12.1797",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M12.1797 5.81781L14.3022 3.69531",
                                    stroke: "#EFA12C",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "mt-1 ml-1",
                                staticStyle: {
                                  "font-family": "Mulish",
                                  "font-style": "normal",
                                  "font-weight": "normal",
                                  "font-size": "14px",
                                  "line-height": "21px",
                                  color: "#171822"
                                }
                              },
                              [
                                _vm._v(" To be completed on Jan 18, 2022 "),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "normal",
                                      "font-size": "12px",
                                      "line-height": "15px",
                                      color: "#949494"
                                    }
                                  },
                                  [_vm._v(" Non-renewal")]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditMandatoryTrainingForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "SlashIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Mandatory Training ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c("b-card-body", [
                        _c("span", [
                          _vm._v(
                            "Choose the trainings that are applicable for the staff’s role. "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Infection Control Anaphylaxis")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "start_date",
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Basic Life Support / CPR 41 ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Management of Emergency Situations "
                                    )
                                  ]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Health and Safety Fire Safety ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Evacuation ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Information Governance / GDPR ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Equality and Diversity ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "50px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Child Protection ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "50px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Adult Safeguarding ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "50px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Moving and Handling ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "50px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Conflict Resolution ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "140px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Bullying and Harassment ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Date of Training")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-datepicker", {
                                              staticClass: "form-control",
                                              attrs: {
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit"
                                                },
                                                placeholder: "Select",
                                                "start-weekday": "1",
                                                locale: "en-UK",
                                                trim: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Renewal frequency")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticStyle: {
                                          "margin-left": "6px",
                                          "margin-top": "6px"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "name",
                                            placeholder: "Select"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      { staticStyle: { "margin-top": "32px" } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "todo-title" },
                                          [_vm._v("Non-renewal")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-1",
                            staticStyle: {
                              height: "50px",
                              border: "1px solid #E5E5E5",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 mt-1 mb-2",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      "line-height": "21px",
                                      color: "#171822"
                                    }
                                  },
                                  [_vm._v(" Prevent Strategy ")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "ml-auto mr-1 mt-1",
                                    attrs: { name: "check-button", switch: "" },
                                    on: { change: function($event) {} }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-left" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "CheckIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "switch-icon-right" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "XIcon" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-4" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { "400": true }
                                  }
                                ],
                                attrs: { type: "submit", variant: "primary" }
                              },
                              [_vm._v(" Save ")]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(186, 191, 199, 0.15)",
                                    expression: "'rgba(186, 191, 199, 0.15)'",
                                    modifiers: { "400": true }
                                  }
                                ],
                                staticClass: "text-primary ml-1 shadow",
                                attrs: {
                                  type: "button",
                                  variant: "outline-white"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.openEditMandatoryTrainingForm = false
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }