var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _vm.openEditWorkingHoursForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "CalendarIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: " font-weight-700" },
                                    [_vm._v(" Working Hours ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openWorkingHoursForm(
                                        _vm.isActiveEditWorkingHoursForm
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _vm._l(_vm.workingHours, function(day, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: { "margin-left": "5px" }
                                  },
                                  [
                                    _c("b-col", { attrs: { cols: "4" } }, [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "2",
                                            height: "18",
                                            viewBox: "0 0 2 18",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              width: "2",
                                              height: "18",
                                              fill: "#00C49A"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-1",
                                          staticStyle: {
                                            "font-family": "Mulish",
                                            "font-style": "normal",
                                            "font-weight": "bold",
                                            "font-size": "14px",
                                            "line-height": "18px",
                                            color: "#171822"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(day.day) + " ")]
                                      )
                                    ]),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "8" } },
                                      _vm._l(day.times, function(time, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            staticClass: "mr-2",
                                            staticStyle: {
                                              "font-family": "Mulish",
                                              "font-style": "normal",
                                              "font-weight": "normal",
                                              "font-size": "14px",
                                              "line-height": "18px",
                                              color: "#171822"
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-1",
                                              attrs: { icon: "ClockIcon" }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  time.start + " - " + time.end
                                                )
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "38px",
                                "background-color": "#EBF5FB",
                                "margin-top": "30px"
                              }
                            },
                            [
                              _c("span", { staticClass: "m-1" }, [
                                _vm._v(
                                  "Total hours/week: " + _vm._s(_vm.total) + " "
                                )
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditWorkingHoursForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "CalendarIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: " font-weight-700" },
                                    [_vm._v(" Working Hours ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("b-form-group", {
                                    attrs: { label: "Days" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-1",
                                  attrs: { cols: "12", md: "12" }
                                },
                                _vm._l(_vm.days, function(day, index) {
                                  return _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      key: index,
                                      staticClass: "btn-icon rounded-circle ",
                                      attrs: { variant: "white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.WorkingHoursToggleDaySelection(
                                            day
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("b-avatar", {
                                        class: _vm.isWorkingHourAvailableForThisDay(
                                          day.day
                                        )
                                          ? "text-white bg-primary "
                                          : "text-dark border-secondary",
                                        style: _vm.isWorkingHourAvailableForThisDay(
                                          day.day
                                        )
                                          ? "backgroundColor:#1B9AAA !important"
                                          : "backgroundColor:white",
                                        attrs: { text: day.text }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "pt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.workingHours.length,
                                      expression: "workingHours.length"
                                    }
                                  ],
                                  attrs: { cols: "12", md: "12" }
                                },
                                [
                                  _c("b-form-group", {
                                    attrs: { label: "Working Hours" }
                                  })
                                ],
                                1
                              ),
                              _c("b-col", {
                                staticClass: "mb-1",
                                attrs: { cols: "12", md: "12" }
                              })
                            ],
                            1
                          ),
                          _c("validation-observer", {
                            ref: "workingHoursForm",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var handleSubmit = ref.handleSubmit
                                    return [
                                      _c(
                                        "b-form",
                                        {
                                          ref: "form",
                                          staticClass: "repeater-form "
                                        },
                                        _vm._l(_vm.workingHours, function(
                                          day,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "mb-1" },
                                            _vm._l(day.times, function(
                                              time,
                                              innerIndex
                                            ) {
                                              return _c(
                                                "b-row",
                                                { key: innerIndex },
                                                [
                                                  innerIndex === 0
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mt-1",
                                                          attrs: {
                                                            cols: "12 pr-0",
                                                            md: "2"
                                                          }
                                                        },
                                                        [
                                                          _c("h6", [
                                                            _vm._v(
                                                              _vm._s(day.day)
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                vid:
                                                                  "StartTime" +
                                                                  index +
                                                                  innerIndex,
                                                                name:
                                                                  "Start time",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "flat-pickr",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              config: {
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat:
                                                                                  "H:i:ss"
                                                                              },
                                                                              placeholder:
                                                                                "Start time"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .workingHours[
                                                                                  index
                                                                                ]
                                                                                  .times[
                                                                                  innerIndex
                                                                                ]
                                                                                  .start,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .workingHours[
                                                                                    index
                                                                                  ]
                                                                                    .times[
                                                                                    innerIndex
                                                                                  ],
                                                                                  "start",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "workingHours[index].times[innerIndex].start"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark font-weight-700",
                                                      staticStyle: {
                                                        "margin-top": "5px"
                                                      }
                                                    },
                                                    [_vm._v(" - ")]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                vid:
                                                                  "EndTime" +
                                                                  index +
                                                                  innerIndex,
                                                                name:
                                                                  "End time",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "flat-pickr",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              config: {
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat:
                                                                                  "H:i:ss"
                                                                              },
                                                                              placeholder:
                                                                                "End time"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .workingHours[
                                                                                  index
                                                                                ]
                                                                                  .times[
                                                                                  innerIndex
                                                                                ]
                                                                                  .end,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .workingHours[
                                                                                    index
                                                                                  ]
                                                                                    .times[
                                                                                    innerIndex
                                                                                  ],
                                                                                  "end",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "workingHours[index].times[innerIndex].end"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark font-weight-700",
                                                      staticStyle: {
                                                        "margin-top": "5px"
                                                      }
                                                    },
                                                    [_vm._v(" - ")]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c("b-form-input", {
                                                            staticClass: "mb-1",
                                                            attrs: {
                                                              type: "number",
                                                              id: "h-last-name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .workingHours[
                                                                  index
                                                                ].times[
                                                                  innerIndex
                                                                ].bMinute,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .workingHours[
                                                                    index
                                                                  ].times[
                                                                    innerIndex
                                                                  ],
                                                                  "bMinute",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "workingHours[index].times[innerIndex].bMinute"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "mins" },
                                                    [_vm._v("mins")]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "5px",
                                                        color: "#1B9AAA"
                                                      },
                                                      attrs: {
                                                        cols: "12 pr-0",
                                                        md: "1"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.hoursCalculate(
                                                                  _vm
                                                                    .workingHours[
                                                                    index
                                                                  ]
                                                                )
                                                              ) + " hrs"
                                                            )
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "white"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.TotalHoursCal(
                                                                    _vm
                                                                      .workingHours[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3044815745
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "38px",
                                "background-color": "#EBF5FB",
                                "margin-bottom": "30px"
                              }
                            },
                            [
                              _c("span", { staticClass: "m-1" }, [
                                _vm._v(
                                  "Total hours/week: " +
                                    _vm._s(
                                      parseFloat(
                                        _vm.totalRequestedTime
                                      ).toFixed(1)
                                    )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { type: "submit", variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.WorkingHoursSubmitForm($event)
                                    }
                                  }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-1 shadow",
                                  attrs: {
                                    type: "button",
                                    variant: "outline-white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeEditeForm()
                                    }
                                  }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditUnavailabilityForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "SlashIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Unavailability ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openForm(
                                        _vm.isActiveEditUnavailabilityForm
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        _vm._l(_vm.unavailabilities, function(day, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { "margin-left": "5px" }
                                },
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "2",
                                          height: "18",
                                          viewBox: "0 0 2 18",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("rect", {
                                          attrs: {
                                            width: "2",
                                            height: "18",
                                            fill: "#00C49A"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-1",
                                        staticStyle: {
                                          "font-family": "Mulish",
                                          "font-style": "normal",
                                          "font-weight": "bold",
                                          "font-size": "14px",
                                          "line-height": "18px",
                                          color: "#171822"
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(day.day) + " ")]
                                    )
                                  ]),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "8" } },
                                    _vm._l(day.times, function(time, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          staticClass: "mr-2",
                                          staticStyle: {
                                            "font-family": "Mulish",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "14px",
                                            "line-height": "18px",
                                            color: "#171822"
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-1",
                                            attrs: { icon: "ClockIcon" }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                time.start + " - " + time.end
                                              )
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditUnavailabilityForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "6", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "SlashIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Edit Unavailability ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("b-form-group", {
                                    attrs: { label: "Days" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-1",
                                  attrs: { cols: "12", md: "12" }
                                },
                                _vm._l(_vm.days, function(day, index) {
                                  return _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      key: index,
                                      staticClass: "btn-icon rounded-circle ",
                                      attrs: { variant: "white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleDaySelection(day)
                                        }
                                      }
                                    },
                                    [
                                      _c("b-avatar", {
                                        class: _vm.isUnAvailabilitiesAvailableForThisDay(
                                          day.day
                                        )
                                          ? "text-white bg-primary "
                                          : "text-dark border-secondary",
                                        style: _vm.isUnAvailabilitiesAvailableForThisDay(
                                          day.day
                                        )
                                          ? "backgroundColor:#1B9AAA !important"
                                          : "backgroundColor:white",
                                        attrs: { text: day.text }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "pt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.unavailabilities.length,
                                      expression: "unavailabilities.length"
                                    }
                                  ],
                                  attrs: { cols: "12", md: "12" }
                                },
                                [
                                  _c("b-form-group", {
                                    attrs: { label: "Unavailable Hours" }
                                  })
                                ],
                                1
                              ),
                              _c("b-col", {
                                staticClass: "mb-1",
                                attrs: { cols: "12", md: "12" }
                              })
                            ],
                            1
                          ),
                          _c("validation-observer", {
                            ref: "unavailabilityForm",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var handleSubmit = ref.handleSubmit
                                    return [
                                      _c(
                                        "b-form",
                                        {
                                          ref: "form",
                                          staticClass: "repeater-form "
                                        },
                                        _vm._l(_vm.unavailabilities, function(
                                          day,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "mb-1" },
                                            _vm._l(day.times, function(
                                              time,
                                              innerIndex
                                            ) {
                                              return _c(
                                                "b-row",
                                                { key: innerIndex },
                                                [
                                                  innerIndex === 0
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mt-1",
                                                          attrs: {
                                                            cols: "12 pr-0",
                                                            md: "2"
                                                          }
                                                        },
                                                        [
                                                          _c("h6", [
                                                            _vm._v(
                                                              _vm._s(day.day)
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mt-1",
                                                          attrs: {
                                                            cols: "12 pr-0",
                                                            md: "2"
                                                          }
                                                        },
                                                        [_c("h6")]
                                                      ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "4"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                vid:
                                                                  "StartTime" +
                                                                  index +
                                                                  innerIndex,
                                                                name:
                                                                  "Start time",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "flat-pickr",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              config: {
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat:
                                                                                  "H:i:ss"
                                                                              },
                                                                              placeholder:
                                                                                "Start time"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .unavailabilities[
                                                                                  index
                                                                                ]
                                                                                  .times[
                                                                                  innerIndex
                                                                                ]
                                                                                  .start,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .unavailabilities[
                                                                                    index
                                                                                  ]
                                                                                    .times[
                                                                                    innerIndex
                                                                                  ],
                                                                                  "start",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "unavailabilities[index].times[innerIndex].start"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "4"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "set-mb"
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                vid:
                                                                  "EndTime" +
                                                                  index +
                                                                  innerIndex,
                                                                name:
                                                                  "End time",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "flat-pickr",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              config: {
                                                                                enableTime: true,
                                                                                noCalendar: true,
                                                                                dateFormat:
                                                                                  "H:i:ss"
                                                                              },
                                                                              placeholder:
                                                                                "End time"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .unavailabilities[
                                                                                  index
                                                                                ]
                                                                                  .times[
                                                                                  innerIndex
                                                                                ]
                                                                                  .end,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .unavailabilities[
                                                                                    index
                                                                                  ]
                                                                                    .times[
                                                                                    innerIndex
                                                                                  ],
                                                                                  "end",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "unavailabilities[index].times[innerIndex].end"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  day.times.length ===
                                                  innerIndex + 1
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            cols: "12",
                                                            md: "2"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "ripple",
                                                                  rawName:
                                                                    "v-ripple.400",
                                                                  value:
                                                                    "rgba(113, 102, 240, 0.15)",
                                                                  expression:
                                                                    "'rgba(113, 102, 240, 0.15)'",
                                                                  modifiers: {
                                                                    "400": true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn-icon rounded-circle p-0",
                                                              attrs: {
                                                                size: "20",
                                                                variant:
                                                                  "outline-white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.addSlot(
                                                                    _vm
                                                                      .unavailabilities[
                                                                      index
                                                                    ].times
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon:
                                                                      "PlusCircleIcon",
                                                                    size: "17"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "ripple",
                                                                  rawName:
                                                                    "v-ripple.400",
                                                                  value:
                                                                    "rgba(113, 102, 240, 0.15)",
                                                                  expression:
                                                                    "'rgba(113, 102, 240, 0.15)'",
                                                                  modifiers: {
                                                                    "400": true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn-icon rounded-circle",
                                                              attrs: {
                                                                size: "20",
                                                                variant:
                                                                  "outline-white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeSlot(
                                                                    index,
                                                                    innerIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon:
                                                                      "MinusCircleIcon",
                                                                    size: "17"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            cols: "12",
                                                            md: "2"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "ripple",
                                                                  rawName:
                                                                    "v-ripple.400",
                                                                  value:
                                                                    "rgba(113, 102, 240, 0.15)",
                                                                  expression:
                                                                    "'rgba(113, 102, 240, 0.15)'",
                                                                  modifiers: {
                                                                    "400": true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn-icon rounded-circle pl-0",
                                                              attrs: {
                                                                size: "20",
                                                                variant:
                                                                  "outline-white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeSlot(
                                                                    index,
                                                                    innerIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon:
                                                                      "MinusCircleIcon",
                                                                    size: "17"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              371125230
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { type: "submit", variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.submitForm($event)
                                    }
                                  }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-1 shadow",
                                  attrs: {
                                    type: "button",
                                    variant: "outline-white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancellworkingHoursChanges()
                                    }
                                  }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }