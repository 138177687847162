<template>
  <b-sidebar
      id="request-leave-side-bar"
      :visible="isRequestLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
       @change="(val) => $emit('update:is-request-leave-sidebar-active', val)"

  >
   <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Add Leave
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
      >
      <b-overlay
        :show="formLoading"
        rounded="sm"
            >
        <!-- Form -->
        <div
            class="p-2"
        >
          <div class="alert d-flex font-weight-bold">
            <label class=" font-small-4 ">
              Use this form to create leave for practice staff. If you want to add leave to yourself, it won’t require approval.</label>
          </div>
          <b-row class="mb-1">
            <b-col cols="12" >
            <label>Add Leave For</label>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="Staff"
                  :rules="admin_leave ? '' : 'required'"
              >
                <v-select
                    label="name"
                    placeholder="Select Staff"
                    v-model="StaffMember"
                    :reduce="name => name.id"
                    :options="options"
                    :disabled="disableStaff"
                    multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                <b-form-checkbox
                  v-model="admin_leave"
                  :value="true"
                >
                Add leave for myself
              </b-form-checkbox>
            </b-col>
          </b-row>
            <b-row class="mb-1">
                <b-col md="12" class="">
                  <h5 class="cursor-pointer">
                    Leave Type</h5>
                    <label>Use day off if the staff member is taking one or more full working day ({{practiceWorkingHours}} hours). Otherwise use hours off.</label>
                </b-col>

                <b-col md="12">
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-radio
                        v-model="selectedLeaveType"
                        value="timeOff"
                        >
                        Hours off
                        <feather-icon
                            v-b-tooltip.hover.top="'You will be unavailable for'+'\n'+'specific hours'"
                            class="text-primary cursor-pointer"
                        style="margin-left: 10px; margin-bottom: 3px;"
                        icon="InfoIcon"
                        size="12" />
                      </b-form-radio>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-radio
                    v-model="selectedLeaveType"
                    value="dayOff"
                    >
                      Full working days off
                        <feather-icon
                            v-b-tooltip.hover.top="'You will be unavailable for'+'\n'+'more than one full day'"
                            class="text-primary cursor-pointer"
                        style="margin-left: 10px; margin-bottom: 3px;"
                        icon="InfoIcon"
                        size="12" />
                    </b-form-radio>
                    </b-col>
                  </b-row>

                </b-col>
            </b-row>
          <b-form
                ref="form"
                :style="{height: trHeight}"
                class="repeater-form"
                @submit.prevent="repeateAgain"
                v-if="selectedLeaveType === 'timeOff'"
              >
          <b-row v-for="(item, index) in leaveItems"
                      :id="item.id"
                      :key="item.id"
                      ref="row">
            <b-col cols="12" md="4">
              <label>Date</label>
              <validation-provider
                  ref="date"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Date'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                   <b-form-datepicker
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="leaveItems[index].date"
                        placeholder="Select"
                        start-weekday="1"
                        :min="min"
                        class="form-control"
                        locale='en-UK'
                        trim/>
                  <!-- <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr

                      v-model="leaveItems[index].date"
                      :config="{ enableTime: false, noCalendar: false, minDate:'today', dateFormat: 'Y-m-d', locale: {
                        firstDayOfWeek: 1
                    }}"
                      class="form-control"
                      placeholder="Select"
                  /> -->
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The Date field is required</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="3">
              <label>Start Time</label>
              <validation-provider
                  ref="startTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Start Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveItems[index].startTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="3">
              <label>End Time</label>
              <validation-provider
                  ref="endTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'End Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveItems[index].endTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The End Time field is required</small>
              </validation-provider>
            </b-col>
            <b-col class="" style="margin-top:25px" cols="12" md="2">
              <b-button v-if="leaveItems.length == index + 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="repeateAgain"
              >
                <feather-icon icon="PlusCircleIcon" size="17"/>
              </b-button>
              <b-button
                  v-if="leaveItems.length > 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="removeItem(index)"
              >
                <feather-icon icon="MinusCircleIcon" size="17"/>
              </b-button>
            </b-col>
          </b-row>
          </b-form>
          <b-form v-if="selectedLeaveType === 'dayOff'">
          <b-row >
            <b-col cols="12" md="4">
              <label>Start Date</label>
              <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-form-datepicker
                        id="start_date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="startDate"
                        placeholder="Select"
                        start-weekday="1"
                        :min="min"
                        class="form-control"
                        locale='en-UK'
                        trim/>
                  <!-- <flat-pickr
                      v-model="startDate"
                      :config="{ enableTime: false, noCalendar: false, minDate:'today', dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1
                    } }"
                      class="form-control"
                      placeholder="Select"
                  /> -->
                </b-input-group>
              </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4">
              <label>End Date</label>
              <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                   <b-form-datepicker
                        id="end_date"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="endDate"
                        :disabled="disableEndDate"
                        start-weekday="1"
                        :min="minDate"
                        class="form-control"
                        placeholder="Select"
                        locale='en-UK'
                        trim/>
                  <!-- <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="endDate"
                      :config="{ enableTime: false, noCalendar: false,  minDate:minDate, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1
                      }}"
                      :disabled="disableEndDate"
                      class="form-control"
                      placeholder="Select"
                  />  -->
                </b-input-group>
              </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          </b-form>
          <b-row>
            <b-col class="mt-1" cols="12">
              <label>Reason</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="leaveReason"
                      :options="leaveReasonOptions"
                      :reduce="leaveReason => leaveReason.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="showSicknesInput">
            <b-col class="mt-1" cols="12">
              <label>Sickness Type</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="sicknessType"
                      :options="sicknessTypeOptions"
                      :reduce="sicknessType => sicknessType.id"
                      label="name"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex  mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="saveDisable()"
                @click="addLeave()"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>


<script>
import {
  VBTooltip,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import leave from '@/apis/modules/leave'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import ProfileAPI from '@/apis/modules/profile'
import staffBank from '@/apis/modules/staffBank'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'

export default {
  mixins: [MomentMixin, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    VBTooltip,
    BFormRadioGroup

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },

  model: {
    prop: 'isRequestLeaveSidebarActive',
    event: 'update:is-request-leave-sidebar-active'
  },
  props: {
    isRequestLeaveSidebarActive: {
      type: Boolean,
      required: true
    },
  data () {
    const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth() )
      minDate.setDate(minDate.getDate())
    return {
      min: minDate,
      currentUserId:'',
      addLeaveForAllStaff:false,
      leaveReasonOptions:[],
      sicknessTypeOptions:[],
      showSicknesInput: false,
      trHeight: null,
      required,
      date: '',
      startTime: '',
      endTime: '',
      alphaNum,
      tableLoading: false,
      isActive: true,
      items: [],
      nextTodoId: 1,
      selectedLeaveType:'timeOff',
      StaffMember:[],
      admin_leave:false,
      Reason:'',
      formLoading:false,
      leaveReason:'',
      sicknessType:'',
      startDate:'',
      endDate:'',
      practiceWorkingHours:0,
      defaultItem:{
          id: 'new_' + 0,
          startTime: '',
          endTime: '',
          date: ''
      },
      leaveItems:[{
          id: 1,
          date:'',
          startTime:'',
          endTime:''
        }],
      options:[],
      disableStaff:false,
      disableEndDate: true,
      minDate:'',
      isActive: true,
    }
  },

  watch: {
    StaffMember(val){
      if(val.length > 1){
        val.forEach(e => {
        if(e == 0){
          this.StaffMember = [0]
        }
      });
      }
    },
    async admin_leave(){
      if(this.admin_leave){
        this.disableStaff = true
        await this.addLeaveMySelft()
      }else{
        this.disableStaff = false
      }
    },
    'startDate': function(value){
      this.minDate = new Date(value)
      if(value) this.disableEndDate = false
    },
    async leaveReason(data){
      await this.getSicknessTypes(data)
    }
  },
  methods: {
    saveDisable() {
      if(this.selectedLeaveType === 'timeOff' ){
        if((this.StaffMember || this.admin_leave === 'true') && this.leaveItems[0].date && this.leaveItems[0].startTime && this.leaveItems[0].endTime && this.leaveReason){
          return !this.isActive
        }else{
          return this.isActive === true
        }
      }else {
        if((this.StaffMember || this.admin_leave === 'true') && this.startDate && this.endDate && this.leaveReason){
          return !this.isActive
        }else{
          return this.isActive === true
        }
      }
    },

    toggle () {
      this.isAddLeaveSidebarActive = false
    },
    repeateAgain () {
      this.leaveItems.push({
        id: this.nextTodoId += 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.leaveItems.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    async addLeave() {
      if (await this.$refs.refFormObserver.validate()) {
        try {
          let response = [];
          let user_ids = [];
          if(this.StaffMember[0]==0){
            this.options.forEach(e =>{
              if(!e.id == 0){
                user_ids.push(e.id);
              }
            });
          }else{
            user_ids = this.StaffMember
          }
          let formData = new FormData
          if (this.selectedLeaveType === 'timeOff') {
            let leaves = []
            leaves = this.leaveItems.map((x) => ({
                  start: moment(x.date + ` ` + x.startTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                  end: moment(x.date + ` ` + x.endTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                })
            )

            formData.append('leaveItems', JSON.stringify(leaves))
            formData.append('start', this.leaveItems[0].date + ` ` + this.leaveItems[0].startTime)
            formData.append('user_id', JSON.stringify(user_ids))
            formData.append('leave_reason', this.leaveReason)
            formData.append('sickness_type', this.sicknessType)
            formData.append('is_day', false)
            response = await leave.requestAdminAndStaffLeaves(formData)
          } else {
            formData.append('start', moment(this.startDate).utc().format('YYYY-MM-DD HH:mm:ss'))
            formData.append('end', moment(this.endDate).utc().format('YYYY-MM-DD HH:mm:ss'))
            formData.append('user_id', JSON.stringify(user_ids))
            formData.append('is_day', true)
            formData.append('leave_reason', this.leaveReason)
            formData.append('sickness_type', this.sicknessType)
            response = await leave.requestAdminAndStaffLeaves(formData)
          }
          if(response.data) {
            this.showSuccessMessage('Leave requested successfully')
            this.$emit('toggleSideBars')
            this.resetForm()
          }

        } catch (error) {
          this.convertAndNotifyError(error)
        }
     }

  },

    saveDisable(){
      if(this.selectedLeaveType === 'timeOff'){
        if(this.StaffMember && this.leaveItems[0].startTime && this.leaveItems[0].endTime && this.leaveReason){
          return !this.isActive
        }else{
          return this.isActive === true
        }
      }else{
        if(this.StaffMember && this.startDate && this.endDate && this.leaveReason){
          return !this.isActive
        }else{
          return this.isActive === true
        }
      }
    },

    resetForm () {
      this.leaveItems = [{
        id: 1,
        date: '',
        startTime: '',
        endTime: ''
      }],
      this.StaffMember = '',
      this.admin_leave = '',
      this.startDate = '',
      this.endDate = '',
      this.Reason = '',
      this.leaveReason=''
      this.$refs.refFormObserver.reset();
    },
    async getStaff () {
      try {
        const Response = await staffBank.salariedStaffList(this.filterQuery, this.currentPage, 10, this.sortBy, this.sortDesc,'salaried-staff')
        this.options = Response.data.data.map((x) => ({
          id: x.pivot.user_id,
          name: x.first_name + ' ' + x.last_name,
        }))
        let allMember = [
          {
            id: 0,
            name: 'All Staff'
          }
        ]
        this.options = [...this.options, ...allMember]
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async getCurrentUser () {
      try{
        const user = (await ProfileAPI.getCurrentUser()).data.data
        this.currentUserId = user.id

      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    addLeaveMySelft(){
      this.StaffMember = []
      this.StaffMember[0] = this.currentUserId
    },
    async getLeaveReasons(){
      try{
        const leaves = await AdminLeaveAPI.getLeaveReasons()
        this.leaveReasonOptions = leaves.data.data
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getSicknessTypes(data){
      try{
        if(data === 1){
          this.formLoading = true
          const leaves = await AdminLeaveAPI.getSicknessTypes()
          this.sicknessTypeOptions = leaves.data.data
          this.showSicknesInput = true
          this.formLoading = false
        }else{
          this.formLoading = true
          this.sicknessTypeOptions = null
          this.sicknessType = ''
          this.showSicknesInput = false
          this.formLoading = false
        }
      }catch(error){
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    }
  },
  mounted () {
    this.getStaff()
    this.getLeaveReasons()
    this.getCurrentUser()
    this.practiceWorkingHours = this.$store.state.userWorkingHoursPerDay
  },
  }
}
</script>

<style lang="scss" scoped>

#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
