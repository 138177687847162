<template>
  <div>
    <profile-image-bar></profile-image-bar>

<!--    <ProfileSecondCardRow-->
<!--        @toggleEditSideBar="isProfileEditLocationSidebarActive = true"-->
<!--    />-->

<!--    <b-row>-->
<!--&lt;!&ndash;      <b-col cols="12" md="6">&ndash;&gt;-->
<!--&lt;!&ndash;        <ProfileShifts/>&ndash;&gt;-->
<!--&lt;!&ndash;      </b-col>&ndash;&gt;-->
<!--      <b-col cols="12" md="6">-->

<!--        <Notes/>-->
<!--      </b-col>-->
<!--    </b-row>-->

    <!--    Sidebar-->
    <ProfileEditConnectedLocationsSideBar
        :is-profile-edit-location-sidebar-active.sync="isProfileEditLocationSidebarActive"/>

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import ProfileImageBar from "./includes/ProfileImageBar";
// import ProfileShifts from "./includes/ProfileShifts";
// import ProfileSecondCardRow from "./includes/ProfileSecondCardRow";
// import Notes from "./includes/notes";

import ProfileEditConnectedLocationsSideBar from "./sidebars/ProfileEditConnectedLocationsSideBar";
import {
  BCol,
  BImg,
  BRow,

} from 'bootstrap-vue'
export default {
  components: {
    // Notes,
    ProfileEditConnectedLocationsSideBar,
    ProfileImageBar,
    // ProfileShifts,
    // ProfileSecondCardRow,
    VuePerfectScrollbar,
    BCol,
    BImg,
    BRow,
  },
  data() {
    return {
      isAddLeavesSidebarActive: false,
      isProfileEditLocationSidebarActive: false,
    }
  }
}
</script>
