<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Documents
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openFileUploadModel()"
              >
                <feather-icon
                    v-b-tooltip.hover.top="'Add Documents'"
                    icon="PlusIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>

            <b-row class="p-1">

              <b-col cols="12">
                  <b-table
                      ref="table"
                      :current-page="currentPage"
                      :fields="fields"
                      :items="getList"
                      :per-page="pagination.perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      class="mobile_table_css"

                      hover
                      responsive
                  >
                    <template v-slot:head(created_at)="data">
                      <span v-b-tooltip.hover.viewport="'Sort'">{{ data.label }}</span>
                    </template>

                    <template
                        slot="table-row"
                        slot-scope="data"
                    ></template>

                    >

                    <!-- Name $ Avatar -->

                    <template #cell(name)="data"

                    >
                      <b-col>
                        <b-media no-body>

                          <b-img
                              :src="require('@/assets/images/filetypes/pdf.png')"
                              alt="browser img"
                              class="mr-1 "
                              height="30"
                          />
                          <h6 class="align-self-center my-auto font-weight-bolder">
                            {{ data.item.name }}
                          </h6>
                        </b-media>
                      </b-col>
                    </template>

                    <!-- Paid Unpaid Button -->

                    <template #cell(action)="data">
                      <b-button
                          v-b-tooltip.hover.top="'Download'"
                          class="btn-icon bg-white text-primary m-1"
                          size="23"
                          variant="outline-white"
                          @click="downloadFile(data.item)"
                      >
                        <feather-icon icon="DownloadIcon" size="18" variant="outline-primary"/>
                      </b-button>


                      <b-button
                          v-b-tooltip.hover.top="'Delete'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="bg-white text-danger btn-icon ml-1"
                          size="23"
                          variant="outline-danger"
                          @click="deleteFile(data.item.id)"
                      >
                        <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>
                      </b-button>


                    </template>
                  </b-table>
              </b-col>
              <b-col v-if="noDataTable === 0" class="text-center" cols="12">
                <span>No data for preview</span>
              </b-col>


              <!--             Pagination-->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
                <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
              </b-col>

              <!-- Pagination -->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
                <b-pagination
                    v-model="currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    class="mb-0 mt-1 mt-sm-0 "
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>

                </b-pagination>

              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-modal
            id="modal-center"
            v-model="showFileUploadModal"
            centered
            hide-footer
            no-close-on-backdrop
            size="lg"
            title="Upload Document"
        >
          <b-overlay
              :show="modalLoading"
              rounded="sm"
          >
            <b-row class="mx-1">
              <b-col class="mt-2" cols="12">
                <div class="m-auto" style="width: 100px ; height: 100px">
                  <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                       viewBox="0 0 512 512" x="0px" xml:space="preserve"
                       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
<rect height="512" style="fill:#32BEA6;" width="512" y="0"/>
                    <g>
	<polygon points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	" style="fill:#FFFFFF;"/>
                      <polygon points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	" style="fill:#FFFFFF;"/>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
</svg>
                </div>
              </b-col>
              <b-col class="mt-3" cols="12">
                <b-overlay
                    :show="buttonLoading"
                    rounded="sm"
                >
                  <v-select
                      v-model="selected_file_type_id"
                      :options="fileTypeOption"
                      :reduce="fileTypeOption => fileTypeOption.id"
                      label="name"
                      placeholder="Select Document Type"
                      value="id"
                      class="mb-2"
                  />

                  <b-form-file
                      ref="image_ref"
                      v-model="uploadedFile"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here..."
                  />
                </b-overlay>
              </b-col>
              <b-col class="mt-5" cols="12">
                <div class="float-right">

                  <b-button
                      :disabled="isActive"
                      class="mr-2"
                      variant="primary"
                      @click="uploadFile()">

                    Upload
                  </b-button>

                  <b-button variant="warning" @click="isActive = true , showFileUploadModal = false">Cancel</b-button>
                  <!--            <b-button @click="downloadFile()">Download</b-button>-->
                  <!--              <b-button @click="getList()">List</b-button>-->
                </div>
              </b-col>
            </b-row>
          </b-overlay>

        </b-modal>



      </b-col>

      <b-col lg="6" md="6" v-if="openEditMandatoryTrainingForm === false">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="BookOpenIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Mandatory Training
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="openForm(isActiveEditMandatoryTrainingForm)"
              >
                <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                />
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Infection Control Anaphylaxis</span>
            </div>

            <b-row>
            <feather-icon
                icon="CheckCircleIcon"
                size="20"
                class="text-primary m-1"
            />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on May 18, 2021 <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Expires on May 18, 2022</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>


            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Basic Life Support / CPR 41</span>
            </div>

            <b-row>
              <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-primary m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on Dec 7, 2021  <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Expires on Dec 7, 2022</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Management of Emergency Situations </span>
            </div>

            <b-row>
              <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-primary m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on May 18, 2021 <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Non-renewal</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Health and Safety Fire Safety</span>
            </div>

            <b-row>
              <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-primary m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on May 18, 2021 <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Expires on Dec 7, 2023</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Evacuation</span>
            </div>

            <b-row>
              <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-primary m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on Dec 24, 2021  <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Expires on Jun 24, 2022</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Information Governance / GDPR </span>
            </div>

            <b-row>
              <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-primary m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on Dec 7, 2021  <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Expires on Dec 7, 2022</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Equality and Diversity </span>
            </div>

            <b-row>
              <feather-icon
                  icon="AlertOctagonIcon"
                  size="20"
                  class="text-danger m-1"
              />

              <span class="mt-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                Completed on Dec 9, 2020 <br>
               <span class="text-danger" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                Expired on Dec 9, 2021</span>
              </span>

              <span
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="text-primary ml-auto mr-2 mt-1"
              >
                View Credential
              </span>
            </b-row>

            <div style="height: 40px; background-color: #EBF5FB; margin-top: 20px">
              <span class="m-1" style="padding-bottom: 5px; font-family: Mulish; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-transform: uppercase; color: #171822;">
                Bullying and Harassment </span>
            </div>

            <b-row>
              <svg width="18" height="18" class="mt-1 ml-1" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.5V4.5" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 13.5V16.5" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.69727 3.69531L5.81977 5.81781" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1797 12.1797L14.3022 14.3022" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.5 9H4.5" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.5 9H16.5" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.69727 14.3022L5.81977 12.1797" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1797 5.81781L14.3022 3.69531" stroke="#EFA12C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span class="mt-1 ml-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                To be completed on Jan 18, 2022 <br>
               <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: #949494">
                 Non-renewal</span>
              </span>

<!--              <b-button-->
<!--                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
<!--                  type="reset"-->
<!--                  class="text-primary ml-auto shadow"-->
<!--                  variant="outline-white">-->
<!--                View Credential-->
<!--              </b-button>-->
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>

      <b-col lg="6" md="6" v-if="openEditMandatoryTrainingForm === true">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="SlashIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Mandatory Training
                </span>
              </div>
            </div>
            <div class="ml-auto">
              <span style="color: #EFA12C;">•</span>
              <span style="margin-left: 5px;">Editing</span>
            </div>
          </b-card-header>
          <b-card-body>
            <span>Choose the trainings that are applicable for the staff’s role. </span>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
             <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                 Infection Control Anaphylaxis</span>

               <b-form-checkbox
                   name="check-button"
                   class="ml-auto mr-1 mt-1"
                   switch
                   v-on:change=""
               >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                 <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
               </b-form-checkbox>
             </b-row>

              <b-row>
                <b-col cols="5">
              <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          id="start_date"
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Basic Life Support / CPR 41 </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Management of Emergency Situations  </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Health and Safety Fire Safety  </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Evacuation  </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Information Governance / GDPR   </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Equality and Diversity   </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 50px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Child Protection   </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>
            </div>

            <div style="height: 50px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Adult Safeguarding    </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>
            </div>

            <div style="height: 50px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Moving and Handling    </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>
            </div>

            <div style="height: 50px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Conflict Resolution   </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>
            </div>

            <div style="height: 140px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
                Bullying and Harassment    </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>

              <b-row>
                <b-col cols="5">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Date of Training</span>

                  <b-form-group class="" style="margin-left: 6px; margin-top: 6px;">
                    <b-input-group class="input-group-merge">
                      <b-form-datepicker
                          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale='en-UK'
                          trim/>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <span class="" style="margin-left: 6px; font-size: 12px;">Renewal frequency</span>
                  <b-form-group style="margin-left: 6px; margin-top: 6px;">
                    <v-select
                        label="name"
                        placeholder="Select"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox class="" style="margin-top: 32px"
                  >
                    <span class="todo-title">Non-renewal</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <div style="height: 50px; border: 1px solid #E5E5E5; border-radius: 4px;" class="mt-1">
              <b-row>
               <span class="ml-2 mt-1 mb-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 21px; color: #171822;">
               Prevent Strategy     </span>

                <b-form-checkbox
                    name="check-button"
                    class="ml-auto mr-1 mt-1"
                    switch
                    v-on:change=""
                >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                  <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                </b-form-checkbox>
              </b-row>
            </div>

            <div class="d-flex mt-4">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="text-primary ml-1 shadow"
                  type="button"
                  variant="outline-white"
                  @click="openEditMandatoryTrainingForm = false"
              >
                Cancel
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BFormDatepicker,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import {baseApiUrl, apiUrl} from '@/constants/config'
import fileUploader from '@/views/my-documents/fileUploader.js'
import flatPickr from 'vue-flatpickr-component'

// import fileUploader from './fileUploader.js'

import profile from '@/apis/modules/profile'
import staffBank from '@/apis/modules/staffBank'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import settings from '@/apis/modules/settings'

export default {
  name: 'documentsAndTraining',
  components: {
    flatPickr,
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data () {
    return {
      isActiveEditMandatoryTrainingForm:true,
      openEditMandatoryTrainingForm:false,
      fileTypeOption:[],
      selected_file_type_id:'',
      isActive: true,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters: {},
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: 'DOCUMENT NAME',
          sortable: true
        },
        {
          key: 'file_type',
          label: 'DOCUMENT TYPE',
          sortable: false
        },

        {
          key: 'created_at',
          label: 'DATE UPLOADED',
          sortable: true
        },
        {
          key: 'updated_by',
          label: 'UPLOADED BY',
          sortable: false
        },

        {
          key: 'action',
          label: ''

        }


      ],
      items: []
    }
  },

  watch: {
    uploadedFile () {
      this.isActive = false
    }
  },

  methods: {
    openForm(val){
      this.openEditMandatoryTrainingForm = val
    },

    async getFileType () {
      try {
        this.tableLoading = true

        const Response = await settings.getFileType()
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }))

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async getList () {
      try {
        this.tableLoading = true

        if (this.sortBy === '') {
          this.sortDesc = true
        }

        const Response = await staffBank.getImagesList(this.currentPage, 15, this.filterQuery, this.sortBy, this.sortDesc)
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          updated_by: x.updated_by.first_name + ' ' + x.updated_by.last_name,
          created_at: this.momentFormat(x.created_at, 'dddd DD-MM-YYYY'),
          file_type: x.file_type.name
        }))


        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }

    },
    async downloadFile (item) {
      try {
        this.tableLoading = true
        // const response =


        const response = await staffBank.downloadImage(item.id)
        axios
            .get(`${apiUrl}/salaried-staff/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`, {
              // headers{
              // }
              responseType: 'arraybuffer',
              withCredentials: true
            })
            .then(response => {

              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()
            })
        this.showSuccessMessage('File Downloaded Successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteFile (id) {

      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to delete this document?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
          if (result.value) {
            await staffBank.deleteToFile(id)
            this.$refs.table.refresh()
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your document has been deleted.',

              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          } else if (result.dismiss === 'cancel') {
            this.formLoading = false
            this.$swal({
              title: 'Cancelled',
              text: ' ',

              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })

        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    resetData() {
      this.selected_file_type_id = ''
      this.uploadedFile = ''
    },

    async uploadFile () {
      try {
        this.buttonLoading = true
        fileUploader.store(this.uploadedFile, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${baseApiUrl}`,

          params: {
            'auth_practice_id': `${sessionStorage.getItem('practice_id')}`
          }
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
          await this.resetData()
          this.isActive = true
        })

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async setImageKeyToApi (key) {

      (await staffBank.getImage({
        key: key,
        name: this.uploadedFile.name,
        file_type_id:this.selected_file_type_id,
      }))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Document Uploaded Successfully')
      this.buttonLoading = false
      this.$refs.table.refresh()
      this.showFileUploadModal = false
    },

    openFileUploadModel () {
      try {
        this.showFileUploadModal = true

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    filterQueryUpdate () {
      this.$refs.table.refresh()
    }
  },
  mounted () {
    this.getFileType()
  }
}
</script>

<style scoped>


</style>

<styl lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</styl>