<template>
  <b-sidebar
      id="add-note-sidebar"
      :visible="isAddNoteSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-note-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Add Note
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <validation-observer ref="requestLeaveForm">
            <b-form @submit.prevent>

            <b-row>
              <b-col md = 12>
                <h4>Note</h4>
                <validation-provider
                    #default="{ errors }"
                    name="Note"
                    rules="required"
                >
                <b-form-textarea
                    v-model="note"
                    id="textarea-default"
                    placeholder="Enter note"
                    rows="3"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
              

              <div class="d-flex mt-3">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    @click="addNote()"
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="text-primary ml-1 shadow"
                    type="button"
                    variant="white"
                    @click="hide"
                >
                  Cancel
                </b-button>
                
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BTable,
  BCard,
  BAvatar,
  BLink,
  BBadge,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BFormSelect,
  BPagination,BFormRadio, BFormRadioGroup,
  BInputGroupAppend, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BOverlay, BFormTextarea


} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Filter from '@/mixins/FilterMixin'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import UserNoteAPI from '@/apis/modules/user_notes'

export default {
  name: 'requestLeave',
  components: {
    flatPickr, BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroup,
    BButton, BFormDatepicker, BFormCheckbox, vSelect, VuePerfectScrollbar, draggable, BRow, BCol, BInputGroupPrepend,
    // Form Validation
    ValidationProvider, ValidationObserver, BCard,
    BAvatar, BLink, BBadge, BCardBody, BCardHeader, BImg, BMedia, BTable, BFormSelect, BPagination, BInputGroupAppend,
    BDropdown,
    BDropdownItem, BListGroup, BListGroupItem, BOverlay, VBTooltip, BFormTextarea,BFormRadio, BFormRadioGroup
  },
  mixins: [Filter, MomentMixin],

  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  model: {
    prop: 'isAddNoteSidebarActive',
    event: 'update:is-add-note-sidebar-active'
  },
  props: {
    isAddNoteSidebarActive: {
      type: Boolean,
      required: true
    },

  },
  data () {
    return {
      note:'',
      selectedLeaveType:'timeOff',
      tableLoading:false,
      selectedCheck: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      
    }
  },
  methods: {
      async addNote(){
          try{
            let payload = {
                user_id: this.$route.params.id,
                is_practice: true,
                note: this.note
            }
            const Response = await UserNoteAPI.addNote(payload)
            this.showSuccessMessage('Note added successfully')
            this.clearData()
            this.$emit('dataRefresh')
          }catch(error){
              this.convertAndNotifyError(error)
          }
      },

    async clearData(){
        this.note = ''
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

